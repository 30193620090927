import React from 'react';
import './Logo.css';

const Logo = () => (
  <div className="logo">
    <a href="/">
      <img src="image/logo.png" alt="Okome Studio" title="Okome Studio" />
    </a>
  </div>
);

export default Logo;
