import React from 'react';
import { HashRouter } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import Logo from './components/Logo';
import Main from './components/Main';
import Navi from './components/Navi';
import './App.css';

const App = () => (
  <HashRouter>
    <div className="page">
      <Logo />
      <Header />
      <div className="spacer" />
      <Navi />
      <Main />
      <Footer />
    </div>
  </HashRouter>
);

export default App;
