import React from 'react';
import './Header.css';

const Header = () => (
  <div className="header-art">
    <span className="hw1">Innovation</span>
    <span className="hw2">Science</span>
    <span className="hw3">Creativity</span>
    <span className="hw4">Information</span>
  </div>
);

export default Header;
