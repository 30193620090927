import React from 'react';
import './Portfolio.css';

const Portfolio = () => (
  <div className="portfolio">
    <div className="media--center">
      <a href="https://github.com/okomestudio">
        <img
          src="image/sns_github.png"
          alt="Visit Okome Studio on Github"
          title="Visit Okome Studio on Github"
        />
        <h3>Okome Studio on GitHub</h3>
      </a>
    </div>
    <p>
      Please see in GitHub the repositories of my public projects and
      {' '}
      <a href="https://github.com/pulls?q=is%3Apr+author%3Aokomestudio+archived%3Afalse+repo%3Aspulec%2Fmoto+repo%3Apaetzke%2Fpy-isort.el+repo%3ABash-it%2Fbash-it+repo%3Agetsentry%2Fresponses+repo%3Ascrapy%2Fw3lib+repo%3Atweepy%2Ftweepy+repo%3Aamueller%2Fword_cloud+repo%3AYelp%2Fmrjob+repo%3Apurcell%2Fsqlformat+">
        open-source contributions
      </a>
      . My resume is also available on request.
    </p>
  </div>
);

export default Portfolio;
