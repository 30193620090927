import React from 'react';
import './Contact.css';

const Contact = () => (
  <div className="contact">
    <div className="media--center">
      <a href="https://twitter.com/intent/user?screen_name=OkomeStudio">
        <img src="image/sns_x.png" alt="X" title="X" />
        <h3>X (Twitter)</h3>
      </a>
    </div>
    <div className="media--center">
      <a href="mailto:info@okomestudio.net">
        <img src="image/sns_mail.png" alt="Email" title="Email" />
        <h3>Email</h3>
      </a>
    </div>
  </div>
);

export default Contact;
