import React from 'react';
import './Knowledge.css';

const Knowledge = () => (
  <div className="knowledge">
    <div className="media--center">
      <a href="https://stackoverflow.com/cv/tarosato">
        <img
          src="image/sns_stackoverflow.svg"
          alt="Profile on Stack Overflow"
          title="Profile on Stack Overflow"
        />
        <h3>Profile on Stack Overflow</h3>
      </a>
    </div>

    <h2>Past Experience</h2>

    <div className="experience flex smaller-text">
      <div className="tile">
        <h2>Software Development</h2>
        <ul className="items">
          <li>
            Python
            <ul>
              <li>Web API (Flask)</li>
              <li>
                Concurrent (threading, gevent) and parallel (multiprocessing)
                programming
              </li>
              <li>Testing (nose, pytest)</li>
              <li>PyData (numpy, scipy, pandas, matplotlib)</li>
              <li>Bottleneck removal (Cython, SWIG, C-extension)</li>
              <li>2.7 to 3.x migration</li>
            </ul>
          </li>
          <li>
            DevOps
            <ul>
              <li>Ansible</li>
              <li>Docker</li>
              <li>Bash programming</li>
            </ul>
          </li>
          <li>
            Low-level performance optimization
            <ul>
              <li>C & C++</li>
            </ul>
          </li>
          <li>
            Databases
            <ul>
              <li>SQL (PostgreSQL, MySQL)</li>
              <li>NoSQL (MongoDB, CouchDB)</li>
              <li>Key-value store (Redis, Memcached)</li>
            </ul>
          </li>
          <li>
            Message queuing system
            <ul>
              <li>AMQP with RabbitMQ</li>
            </ul>
          </li>
          <li>
            Web development
            <ul>
              <li>Web scraping (scrapy)</li>
              <li>LAMP (Linux, Apache, MySQL, Python/PHP)</li>
              <li>JavaScript, HTML, CSS</li>
              <li>React</li>
              <li>Nginx</li>
            </ul>
          </li>
        </ul>
      </div>

      <div className="tile">
        <h2>Data Analysis & Statistics</h2>
        <ul className="items">
          <li>
            General statistical methods
            <ul>
              <li>
                Hypothesis testing: t-test, chi-squared test, Kolmogorov-Smirnov test
              </li>
              <li>
                Regression analysis: simple and multiple linear regressions, non-linear
                least-squares (Levenberg-Marquardt)
              </li>
              <li>Principal component analysis (PCA)</li>
            </ul>
          </li>
          <li>
            Bayesian methods
            <ul>
              <li>MCMC</li>
            </ul>
          </li>
          <li>
            Machine learning
            <ul>
              <li>Classification: logistic regression, Naive Bayes</li>
              <li>Clustering: k-means, hierarchical</li>
            </ul>
          </li>
          <li>
            Natural language processing
            <ul>
              <li>n-gram models</li>
              <li>Text parsing by the hidden Markov model</li>
            </ul>
          </li>
          <li>
            Digital signal processing
            <ul>
              <li>Electromagnetic spectroscopy</li>
              <li>2D image processing</li>
            </ul>
          </li>
          <li>Data visualization</li>
          <ul>
            <li>Interactive visualization with Matplotlib</li>
            <li>Graph visualization with Graphviz/DOT</li>
            <li>D3.js</li>
            <li>Multidimensional scaling (MDS), correspondence analysis</li>
          </ul>
        </ul>
      </div>

      <div className="tile">
        <h2>Cloud & Distributed Computing</h2>
        <ul className="items">
          <li>
            MapReduce
            <ul>
              <li>Hadoop</li>
              <li>mrjob</li>
            </ul>
          </li>
          <li>Apache Spark</li>
          <li>
            Amazon Web Services
            <ul>
              <li>Elastic Compute Cloud (EC2)</li>
              <li>CloudSearch</li>
              <li>EMR</li>
              <li>Lambda</li>
              <li>Relational Database Service (RDS)</li>
              <li>Simple Storage Service (S3)</li>
              <li>Simple Queue Service (SQS)</li>
            </ul>
          </li>
          <li>
            High-performance computing
            <ul>
              <li>OpenMPI</li>
            </ul>
          </li>
        </ul>
      </div>

      <div className="tile">
        <h2>Documentation, Translation, & Localization</h2>
        <ul className="items">
          <li>English-Japanese or Japanese-English</li>
          <li>Technical documentation</li>
        </ul>
      </div>
    </div>
  </div>
);

export default Knowledge;
