import React from 'react';
import './About.css';

const About = () => (
  <div className="about">
    <p>
      Okome Studio is a small software development consultancy, currently a one-man
      operation by Taro Sato, based in San Francisco. The studio focuses on building
      data-oriented systems with Python, Linux, AWS, and related technologies.
    </p>

    <p className="last">
      What is in the name? The interface between science and useful things is full of
      complexities that can be dealt with only through creative thinking. Taking lean
      product development as an artistic process&mdash;be it a well-designed UI frontend
      or a system of backend microservices&mdash;a studio-like workroom is where
      {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
      skillful minds contemplate and innovation materializes. In <i>okome</i>
      (pronounced somewhat like okoumé), we see the simple-yet-essential nature of the
      Japanese staple, with its signature flexibility to suit a wide variety of flavors
      and fine dishes. These are the concepts for which we strive.
    </p>

    <h2>About Taro Sato</h2>

    <img src="image/taro.jpg" className="img_left" alt="Taro" title="Taro" />

    <p>
      Taro has worked as a data scientist and engineer for various startups in the San
      Francisco Bay Area. Before shifting to industry, he had worked as a research
      scientist analyzing astrophysical data and developing scientific computing tools
      in academia. As a long-time open-source user/developer, Taro uses Debian on all
      {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
      his ThinkPads. In his spare time, he enjoys reading and writing reviews of{' '}
      {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
      <a href="https://www.goodreads.com/user/show/7262782-taro">books</a> on a wide
      range of topics, including cultures, technology, and evolutionary sciences.
    </p>
  </div>
);

export default About;
