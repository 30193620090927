import React from 'react';
import './Resume.css';

const Resume = () => (
  <div className="resume">
    <object data="/resume/Taro_Sato.pdf" type="application/pdf">
      <a href="/resume/Taro_Sato.pdf">Taro Sato&apos;s resume</a>
    </object>
  </div>
);

export default Resume;
