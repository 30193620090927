import React from 'react';
import { Route, Switch } from 'react-router-dom';
import About from './About';
import Contact from './Contact';
import Knowledge from './Knowledge';
import Portfolio from './Portfolio';
import Resume from './Resume';
import './Main.css';

const reload = () => window.location.reload();

const Main = () => (
  <main>
    <div className="content">
      <Switch>
        <Route exact path="/" component={About} />
        <Route path="/about" component={About} />
        <Route path="/knowledge" component={Knowledge} />
        <Route path="/portfolio" component={Portfolio} />
        <Route path="/resume" component={Resume} />
        <Route path="/contact" component={Contact} />

        {/* Serve static files below */}
        <Route path="/biboroku/*" onEnter={reload} />
      </Switch>
    </div>
  </main>
);

export default Main;
