import React from 'react';
import './Footer.css';

const Footer = () => (
  <div className="footer">
    Copyright &copy; 2007 - 2024 Okome Studio / Taro Sato. All Rights Reserved.
  </div>
);

export default Footer;
