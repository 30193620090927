import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import './Navi.css';

const Navi = () => {
  const isDefaultPage = (match, location) => {
    if ((!match && location.pathname === '/') || location.pathname === '/about') {
      return true;
    }
    return false;
  };

  return (
    <nav className="clearfix">
      <ul className="site-nav">
        <li>
          <NavLink
            to="/about"
            activeClassName="current-content"
            isActive={isDefaultPage}
          >
            About
          </NavLink>
        </li>
        <li>
          <a href="/biboroku">Blog</a>
        </li>
        <li>
          <NavLink to="/portfolio" activeClassName="current-content">
            Portfolio
          </NavLink>
        </li>
        <li>
          <NavLink to="/contact" activeClassName="current-content">
            Contact
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default withRouter(Navi);
